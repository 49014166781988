import {getQueryStringParams} from './getQueryString';

const storageKey = 'isiOSAppWebview';
const iOSQueryParam = getQueryStringParams().iosapp;

const isIOSApp = iOSQueryParam === 'true';
if (isIOSApp) {
  sessionStorage.setItem(storageKey, iOSQueryParam);
}

export const getIsIOSWebview = () => {
  return sessionStorage.getItem(storageKey);
};
