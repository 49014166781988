/* eslint-disable react/no-deprecated */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { some } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import AccountSettingsRoutes from './components/Nav/AccountSettingsRoutes';
import NavLegacy from './components/Nav/NavLegacy';
import Spinner from '../shared/components/Spinner';
import { AppState } from '../../redux/state';
import { connect, ComponentClass } from 'react-redux';
import {
  PageProperties,
  mapDispatchToProps,
  mapStateToProps,
  DispatcherProps,
} from './containers/accountSettingsContainer';
import { matchPath } from 'react-router';
import MessageList from '../shared/components/MessageList';
import { AccountSettings } from '../shared/CoreClient';
import ResponsiveRender from '../shared/components/ResponsiveRender';
import {
  personalDetailsEditPath,
  paymentDetailsPath,
  vouchersPath,
  subscriptionsPath,
} from './components/Nav/paths';
import NavMobileLegacy from '../shared/components/NavMobileLegacy';
import NavMobile from '../shared/components/NavMobile';
import { Redirect } from 'react-router';
import { getIsIOSWebview } from '../shared/getIsIOSWebview';
import classnames from 'classnames';
import {
  HolidaysMenuItemLegacy,
  MenuItemsLegacy,
} from './components/Nav/MenuItemsLegacy';
import { HolidaysMenuItem, MenuItems } from './components/Nav/MenuItems';
import { ProfileMenuProps } from '@mfb/lego';
import Nav from './components/Nav/Nav';
import createUserManager from 'app/shared/UserManager';
import { navman } from '../../navigator';
import routeHistory from 'routerHistory';

interface Props
  extends RouteComponentProps<
      any,
      any,
      { accountSettingsModel?: AccountSettings }
    >,
    PageProperties,
    AppState {}

const getModelFromLocation = ({
  location,
}: Props): AccountSettings | undefined =>
  location.state && location.state.accountSettingsModel;

class AccountSettingsShell extends React.Component<Props, {}> {
  async componentDidMount(): Promise<void> {
    const { fetchAccountSettingsState } = this.props;
    const accountSettingsModel = getModelFromLocation(this.props);

    if (accountSettingsModel != null) {
      this.setModelFromLocation(this.props, accountSettingsModel);
    } else {
      await fetchAccountSettingsState();
    }
  }

  /* this is used to determine if we need to restore state, primarily to clear error messages
      when navigating around the account settings area */
  async componentWillReceiveProps(nextProps: Readonly<Props>) {
    const accountSettingsModel = getModelFromLocation(nextProps);
    const locationChanged = nextProps.location !== this.props.location;

    if (accountSettingsModel != null) {
      this.setModelFromLocation(nextProps, accountSettingsModel);
    } else if (
      locationChanged &&
      this.matchesPath(this.props.location.pathname)
    ) {
      // If leaving a page that potentially changes the page state, undo those changes
      this.props.restorePageState();
    }
  }

  setModelFromLocation(props: Props, accountSettingsModel: AccountSettings) {
    // If another page has passed in a model, skip reloading and use the supplied model
    props.setAccountSettingsState(accountSettingsModel);
    // Clear the route state to prevent an infinite loop, and ensure that stale state isn't loaded if the user clicks
    // Back in their browser
    props.history.replace({ state: null });
  }

  /* this is a whitelist of edit pages we will use. If we are leaving one of these edit
      pages then we will perform a restore action */
  matchesPath(currentPath: string) {
    const editPages = [
      personalDetailsEditPath,
      `${subscriptionsPath}/:subscriptionId`,
      paymentDetailsPath,
      vouchersPath,
    ];

    return some(editPages, (pathname: string) =>
      matchPath(currentPath, {
        path: pathname,
        exact: true,
        strict: true,
      })
    );
  }

  shouldRender = (props: Props): boolean => {
    const accountSettings = props.pageState as AccountSettings;

    // TODO: figure out a better way to determine if we have al of the available info in redux.
    const hasLoadedCustomer = accountSettings.hasOwnProperty('customer');
    const hasLoadedSubscriptions =
      accountSettings.hasOwnProperty('subscriptions');
    const isAccountSettings = hasLoadedSubscriptions && hasLoadedCustomer;

    return (
      isAccountSettings &&
      !(accountSettings == null || Object.keys(accountSettings).length === 0)
    );
  };

  getProfileInformation(): ProfileMenuProps {
    const profile = this.props.customerProfileState;

    const logout = async () => {
      const userMgr = await createUserManager();
      await userMgr.logout();
    };

    const navigateToSubscription = () => {
      navman.reactivateSubscription();
    };

    return {
      firstName: profile.firstName,
      lastName: profile.lastName,
      productName: profile.productName,
      code: profile.code,
      brandIconUrl: profile.iconSvgUrl,
      deliveryDate: profile.deliveryDate,
      deliveryTime: profile.deliveryTime,
      totalCredits: profile.availableCredit,
      isTotalCreditVisible: true,
      signOutCallback: logout,
      productClickCallback: () => routeHistory.history.push(subscriptionsPath),
      manageSubscriptionsCallback: navigateToSubscription,
    } as ProfileMenuProps;
  }

  render() {
    const accountSettings = this.props.pageState as AccountSettings;

    const filteredMenuItems = this.props.features.enableCustomerAddressHoliday
      ? [...MenuItems, HolidaysMenuItem]
      : MenuItems;

    const filteredMenuItemsLegacy = this.props.features
      .enableCustomerAddressHoliday
      ? [...MenuItemsLegacy, HolidaysMenuItemLegacy]
      : MenuItemsLegacy;

    const messages = accountSettings.messages;

    if (this.props.ui.isLoading) {
      return <Spinner />;
    }

    const isIOSAppWebView = getIsIOSWebview();

    return (
      !this.props.ui.isLoading && (
        <div
          className={`fs-u-pb-80 ${
            this.props.features.dashboardV2 ? 'pt-4' : 'pt-md-4'
          } container mfb-u-overflow-hide`}
        >
          {messages && <MessageList messages={messages} />}
          {this.shouldRender(this.props) && (
            <div className="row" data-test="account-settings-container">
              {!isIOSAppWebView && (
                <div
                  className={`d-none ${
                    this.props.features.dashboardV2
                      ? 'd-lg-block'
                      : 'd-md-block'
                  } col-md-4 col-lg-3`}
                >
                  {this.props.features.dashboardV2 ? (
                    <Nav
                      location={this.props.location}
                      menuItems={filteredMenuItems}
                    />
                  ) : (
                    <NavLegacy
                      location={this.props.location}
                      menuItems={filteredMenuItemsLegacy}
                    />
                  )}
                </div>
              )}
              <div
                className={classnames(
                  'col-12',
                  !isIOSAppWebView &&
                    `${
                      this.props.features.dashboardV2 ? 'col-md-12' : 'col-md-8'
                    } col-lg-9`
                )}
              >
                <AccountSettingsRoutes
                  features={this.props.features}
                  defaultRender={() =>
                    this.props.features.dashboardV2 ? (
                      <ResponsiveRender
                        xs={
                          <NavMobile
                            menuItems={filteredMenuItems}
                            profileInfo={this.getProfileInformation()}
                          />
                        }
                        lg={<Redirect to={subscriptionsPath} />}
                      />
                    ) : (
                      <ResponsiveRender
                        xs={
                          <NavMobileLegacy
                            menuItems={filteredMenuItemsLegacy}
                          />
                        }
                        md={<Redirect to={subscriptionsPath} />}
                      />
                    )
                  }
                />
              </div>
            </div>
          )}
        </div>
      )
    );
  }
}

export default connect<AppState, DispatcherProps, RouteComponentProps<any>>(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettingsShell) as ComponentClass<any>;
