import { parse } from 'qs';

interface QueryStringParams {
  campaignCode: string;
  options: string;
  cart: string;
  iosapp: string;
  editWeek: string;
  showEditSubscriptionDetails: boolean;
}

export const getQueryStringParams = (): QueryStringParams => {
  let queryParams: QueryStringParams;
  if (typeof window !== `undefined`) {
    const parsedQs = parse(window.location.search, { ignoreQueryPrefix: true });
    queryParams = {
      campaignCode: parsedQs.campaignCode as string,
      options: parsedQs.options as string,
      cart: parsedQs.cart as string,
      iosapp: parsedQs.iosapp as string,
      editWeek: parsedQs.editWeek as string,
      showEditSubscriptionDetails: parsedQs.showEditSubscriptionDetails === 'true',
    };
  }

  return queryParams;
};
