import React from 'react';
import { AddressFinder } from '../../account-settings/components/AddressFinder';
import { SubmitStage } from './SubmitStage';
import SubmitOrBack from './SubmitOrBack';
import { ChangeBag } from './ChangeBag';
import {
  AccountCreateSubscriptionRequestBody,
  AccountInformationClient,
  AccountMerchSection,
  AccountSettingClient,
  AvailableBag,
  BenefitType,
  CodeValidationResponse,
  Frequency2,
  SubscriptionClient,
  ValidateAddressResponseDto,
  ProductSummaryResponse
} from '../CoreClient';
import { globalSettings as settings } from 'config';
import { navman } from '../../../navigator';
import {
  ReactivateSubscriptionEvent,
  trackReactivateSubscription,
} from '../reactivateSubscriptionTracker';
import {
  setIsValidPromo,
  setValidationMessages,
} from '../../../redux/actions/sharedActions/bagStateAction';
import DeliverySlotSelect from '../../account-settings/components/DeliverySlotSelect';
import { MerchandisingSlots } from '../../merchandising/MerchandisingSlots';
import { CSSProperties, useRef, useState } from 'react';
import {
  OrderFormDiscount,
  OrderFormDiscountType,
  OrderSummary,
  ProductPriceDisplay,
  ProductPriceDisplayContext,
  PromoInputField,
} from '@mfb/cookbook';
import { Colours, AccountTheme, AlertStatus } from '@mfb/lego';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { accountApiClient } from 'app/shared/api';
import { CostPreviewRequestBody as AccountCostPreviewRequestBody } from '@mfb/account-api-client';
import { useAlertToastContext } from '../hooks';
import { TermsAndConditionsMessage } from './TermsAndConditionsMessage';
import { useReactivationTracking } from './Tracking/useReactivateFormTracking';
import { getIsIOSWebview } from '../getIsIOSWebview';

interface ReactivateSubscriptionPhase2Errors {
  deliveryAddressError?: string;
  deliveryInstructionsError?: string;
  deliveryScheduleDayAndTimeError?: string;
  promoCodeError?: string;
  submitStage?: SubmitStage;
}

export interface ReactivateSubscriptionPhase2Props
  extends AccountCreateSubscriptionRequestBody {
  fullAddress?: string;
  bagName?: string;
  bagId?: number;
  brandName?: string;
  blurb?: string;
  availableBags?: AvailableBag[];
  productPriceDisplayContext?: ProductPriceDisplayContext;
  price?: number;
  deliveryDate?: Date;
  upcomingWeek?: Date;
  deliveryDateIsInitialState?: boolean;
  isValidPromo?: boolean;
  referAFriendCode?: string;
  selectedProduct?: ProductSummaryResponse;
  deliverySlotLabel?: string;
  isDeliveryAddressModified?: boolean;
  isDeliveryInstructionsModified?: boolean;
}

const ReactivateSubscriptionPhase2Unconnected = (props) => {
  const formRef = useRef<ReactivateSubscriptionPhase2Props>({
    ...props.location.state,
  });

  const defaultSlotLabel = useRef<string>();

  const [promoExpired, setPromoExpired] = React.useState<boolean>();
  formRef.current = {
    ...formRef.current,
    productPriceDisplayContext: formRef.current.productPriceDisplayContext,
  };

  const {trackOnCompleteShippingInformation} = useReactivationTracking();

  const { updateToast } = useAlertToastContext();

  React.useEffect(() => {
    (async () => {
      formRef.current = {
        ...formRef.current,
        deliveryDateIsInitialState: true,
      };
      if (!formRef.current.sku || !formRef.current.upcomingWeek) {
        setPromoBanner(false);
        navman.reactivateSubscription();
      }
      if (formRef.current.promoCode) {
        validatePromo();
      } else {
        getCostPreview();
      }
    })();
  }, []);

  const [formErrors, setFormErrors] =
    useState<ReactivateSubscriptionPhase2Errors>({
      deliveryAddressError: undefined,
      deliveryInstructionsError: undefined,
      deliveryScheduleDayAndTimeError: undefined,
      promoCodeError: undefined,
      submitStage: SubmitStage.Invalid,
    });

  const [pafId, setPafId] = useState<number | undefined>(undefined);
  const [priceContext, setPriceContext] =
    React.useState<ProductPriceDisplayContext>(
      formRef.current.productPriceDisplayContext
    );

  const [promoImageBanner, setPromoImageBanner] =
    React.useState<AccountMerchSection>();

  const displayPromoImageBanner = (
    codeValidationResponse: CodeValidationResponse
  ): AccountMerchSection => {
    const { bannerUrlSmall, bannerUrlMedium, bannerUrlLarge } =
      codeValidationResponse.discount;
    if (!bannerUrlSmall || !bannerUrlMedium || !bannerUrlLarge) {
      return undefined;
    }
    return {
      imageSet: {
        small: codeValidationResponse.discount.bannerUrlSmall,
        medium: codeValidationResponse.discount.bannerUrlMedium,
        large: codeValidationResponse.discount.bannerUrlLarge,
      },
      slotName2: null,
      hyperlink2: null,
      imageSet2: null,
      slotType: 0,
    };
  };
  const [isPromoLoading, setPromoLoading] = useState<boolean>();

  const [deliveryDiscount, setDeliveryDiscount] = useState<OrderFormDiscount>({
    code: '',
    message: undefined,
    type: OrderFormDiscountType.Promotion,
    amount: 0,
  });

  const benefitDisplayTheme = {
    colors: {
      backgroundSecondary: Colours.PROMO_BENEFITS_LIGHT_BLUE,
      text: Colours.CHARCOAL,
    },
  };

  const fieldStyles: CSSProperties = {
    borderRadius: '10px',
    borderWidth: '1px',
    borderColor: Colours.SECONDARY_CREAM,
  };

  const setErrors = (error: ReactivateSubscriptionPhase2Errors) => {
    error = { ...formErrors, ...error };
    let submitStage = null;
    if (
      error.promoCodeError ||
      error.deliveryScheduleDayAndTimeError ||
      error.deliveryAddressError
    ) {
      submitStage = SubmitStage.Invalid;
    } else {
      submitStage = SubmitStage.Valid;
    }
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      ...error,
      submitStage: submitStage,
    }));
  };

  const validateForm = () => {
    if (!formRef.current.fullAddress) {
      setErrors({
        deliveryAddressError: "We're sorry, this address is not valid.",
      });
      return false;
    } else if (!formRef.current.deliveryDateId) {
      setErrors({
        deliveryScheduleDayAndTimeError:
          "We're sorry, delivery schedule is not valid.",
      });
      return false;
    }
    return true;
  };

  const mapErrors = (res) => {
    if (res.status !== 200) {
      let err: string = undefined;
      if (res.errors.PromoCode) {
        err = res.errors.PromoCode[0];
        setErrors({ promoCodeError: err });
      } else if (res.errors.Source) {
        setErrors({
          deliveryScheduleDayAndTimeError:
            "We're Sorry, this slot is currently unavailable.",
        });
      } else {
        setErrors({
          deliveryScheduleDayAndTimeError:
            "We're sorry, something went wrong creating your subscription. Please try again.",
        });
      }
    }
  };

  const setDiscountedPriceDisplay = (price, overAllTotal, deliveryPrice) => {
    setPriceDisplay(overAllTotal, deliveryPrice);
    if (price === overAllTotal) {
      return;
    } else {
      const priceBeforeDelivery = overAllTotal - deliveryPrice;
      setPriceContext((prevState) => ({
        ...prevState,
        pricePerWeekBeforeDiscount: `$${price.toFixed(2)}`,
        pricePerWeek: `$${priceBeforeDelivery.toFixed(2)}`,
        totalPrice: `$${overAllTotal.toFixed(2)}`,
      }));
    }
  };

  const setPriceDisplay = (price, deliveryPrice) => {
    setPriceContext({
      pricePerWeek: `$${price.toFixed(2)}`,
      pricePerWeekStringFormat: 'Per Week',
      secondaryButtonHidden: true,
      primaryButtonHidden: true,
    });
    if (deliveryPrice) {
      const totalPrice = price + deliveryPrice;
      setPriceContext((prevState) => ({
        ...prevState,
        deliveryPrice: `$${deliveryPrice.toFixed(2)}`,
        totalPrice: `$${totalPrice.toFixed(2)}`,
      }));
    }
  };

  const setDeliveryDiscountDisplay = (discount: string[]) => {
    setDeliveryDiscount({
      code: '',
      message: discount,
      type: OrderFormDiscountType.Promotion,
      amount: 0,
    });
  };

  const getCostPreview = async () => {
    const request = new AccountCostPreviewRequestBody({
      sku: formRef.current.sku,
      promoCode: formRef.current.promoCode,
      selections: [],
      extras: [],
    });
    try {
      const res = await accountApiClient.subscriptionsCostCalculate(
        formRef.current.upcomingWeek,
        request
      );
      return res;
    } catch (e) {
      return null;
    }
  };

  const setPromoBanner = (isValid: boolean, msg?: Array<string>) => {
    props.setIsValidPromo(isValid);
    formRef.current = { ...formRef.current, isValidPromo: isValid };
    props.setValidationMessages(msg || []);
  };

  const validatePromo = async () => {
    if (!formRef.current.promoCode) {
      setErrors({ promoCodeError: undefined });
      setPromoImageBanner(undefined);
      return;
    }

    setErrors({ promoCodeError: undefined });
    if (formRef.current.promoCode == '') {
      setPriceDisplay(
        formRef.current.price,
        formRef.current.productPriceDisplayContext.deliveryPrice
      );
      setPromoImageBanner(undefined);
      return;
    }
    let validatePromoResp: CodeValidationResponse = null;
    let costPreviewRes = null;
    try {
      validatePromoResp = await new SubscriptionClient(
        settings.bffHost
      ).validatePromoCode(formRef.current.sku, formRef.current.promoCode);

      // show validation errors, if any
      if (validatePromoResp.validationErrors.length > 0) {
        costPreviewRes = await getCostPreview();
        setPromoBanner(false);
        throw new Error(validatePromoResp.validationErrors[0]);
      }

      costPreviewRes = await getCostPreview();
      if (validatePromoResp.discount) {
        //check if promo is only valid for specific product
        if (validatePromoResp.discount.validForProducts.length > 0) {
          const discountProduct =
            validatePromoResp.discount.validForProducts.filter(
              (e) => e.itemNumber == formRef.current.sku
            )[0];

          //promo code is not available for selected sku
          if (!discountProduct) {
            throw new Error(validatePromoResp.validationErrors[0]);
          }
        }
        //check if expired
        if (new Date(validatePromoResp.discount.redeemableTo) > new Date()) {
          if (costPreviewRes && costPreviewRes.pricing) {
            if (
              costPreviewRes.pricing.appliedDiscount &&
              costPreviewRes.pricing.appliedDiscount.hasDiscountApplied
            ) {
              setDiscountedPriceDisplay(
                costPreviewRes.pricing.subTotal,
                costPreviewRes.pricing.overallTotal,
                costPreviewRes.shipping.subTotal
              );
              setPromoImageBanner(displayPromoImageBanner(validatePromoResp));
              setPromoBanner(true);
            } else {
              setPriceDisplay(
                costPreviewRes.pricing.overallTotal,
                costPreviewRes.shipping.subTotal
              );
            }
          } else {
            setPromoExpired(true);
            throw new Error(
              validatePromoResp.validationErrors[0] ||
                "We're sorry, this promo code has expired."
            );
            setPriceDisplay(
              costPreviewRes.pricing.overallTotal,
              costPreviewRes.shipping.subTotal
            );
          }
        } else {
          throw new Error(
            validatePromoResp.validationErrors[0] ||
              "We're sorry, this promo code has expired."
          );
        }
        // Set multiple delivery discounts
        setDeliveryDiscountDisplay(
          validatePromoResp.discount.benefits.map((x) => {
            if (x.benefitType === BenefitType.ValueOff) {
              return `$${x.amount} off`;
            } else if (x.benefitType === BenefitType.PercentageOff) {
              return `${x.amount}% off`;
            }
          })
        );
        return;
      }
      throw new Error(
        validatePromoResp.validationErrors[0] ||
          "We're sorry, this code doesn't exist."
      );
    } catch (e) {
      if (validatePromoResp.validationErrors.length > 0) {
        setErrors({
          promoCodeError: validatePromoResp.validationErrors[0],
        });
      } else {
        setErrors({
          promoCodeError: e.message,
        });
      }

      if (costPreviewRes) {
        setPriceDisplay(
          costPreviewRes.pricing.subTotal,
          costPreviewRes.shipping.subtotal
        );
        setDeliveryDiscountDisplay([]);
        setPromoImageBanner(undefined);
      }
    }
  };

  const handleSubmit = async () => {
    setFormErrors({ promoCodeError: formErrors.promoCodeError });
    setFormErrors((prevState) => ({
      ...prevState,
      submitStage: SubmitStage.Saving,
    }));

    const { customer } = await new AccountSettingClient(
      settings.bffHost
    ).getPageModel();
    const costPreviewResponse = await getCostPreview();
    // building product for ga tracking
    const product: ProductSummaryResponse = {
      name: formRef.current.bagName,
      sku: formRef.current.sku,
      totalPrice: costPreviewResponse.primaryProduct.price,
      availableFrequencies: [formRef.current.frequency],
      settings: {canEditFromSubscription: false, canPurchaseFromSubscription: false},
      isDemandLocked: false,
      isVisible: false,
      nights: 0,
      people: 0,
    };

    const totalDiscount =
      costPreviewResponse.primaryProduct.price -
      costPreviewResponse.pricing.overallTotal;

    if (validateForm()) {
      let res = null;
      res = await new SubscriptionClient(
        settings.bffHost
      ).createSubscriptionAsyncV2({
        deliveryDateId: formRef.current.deliveryDateId,
        sku: formRef.current.sku,
        promoCode: formRef.current.promoCode,
        frequency: Frequency2.Weekly,
        overrideDeliverySlotId: undefined,
        addressId: formRef.current.addressId || undefined,
        deliveryInstructions: formRef.current.deliveryInstructions,
        extras: [],
        selections: [],
      });

      try {
        res = await JSON.parse(res);
        mapErrors(res);
        setFormErrors((prevState) => ({
          ...prevState,
          submitStage: SubmitStage.Valid,
        }));
      } catch (e) {
        // SUCCESS CATCH BLOCK LOL
        await trackReactivateSubscription(
          ReactivateSubscriptionEvent.PURCHASE,
          product,
          formRef.current.brandName,
          customer.referAFriendCode,
          formRef.current.promoCode,
          promoExpired ? product.totalPrice : totalDiscount
        );

        trackOnCompleteShippingInformation({
          selectedProduct: formRef.current.selectedProduct,
          selectedBrandName: formRef.current.brandName,
          deliverySlot: formRef.current.deliverySlotLabel ?? defaultSlotLabel.current,
          promoGroupCode: formRef.current.promoCode,
          isDeliveryAddressModified: formRef.current.isDeliveryAddressModified,
          isDeliveryInstructionsModified: formRef.current.isDeliveryInstructionsModified,
        })

        setPromoBanner(false);
        updateToast({
          status: AlertStatus.success,
          isOpen: true,
          text: 'You have successfully added a subscription',
        });

        const param = new URLSearchParams(window.location.search);
        var campaignCode = param.get('campaignCode');

        if (getIsIOSWebview()) {
          navman.backToApp();
        } else if (campaignCode) {
          navman.campaignModal(campaignCode);
        } else {
          navman.yourAccount();
        }
      }
    }
  };

  const handleAddressChange = async (pafId: number, fullAddress: string) => {
    if (pafId === -1) {
      formRef.current = {
        ...formRef.current,
        addressId: pafId,
        fullAddress: '',
      };
      return;
    }
    setErrors({ submitStage: SubmitStage.Invalid });
    try {
      const res: ValidateAddressResponseDto =
        await new AccountInformationClient(settings.bffHost).validateAddress2({
          addressSuggestionId: pafId,
        });
      formRef.current = {
        ...formRef.current,
        fullAddress: fullAddress,
        addressId: res.addressId,
      };

      if (!res.isValid) {
        setErrors({
          deliveryAddressError:
            res.errorMessage ||
            "We're sorry, we do not deliver to this area right now",
        });
        return;
      }

      setErrors({ deliveryAddressError: undefined });
    } catch (e) {
      setErrors({
        deliveryScheduleDayAndTimeError:
          "We're sorry, something went wrong validating your address.",
      });
    }
  };

  return (
    <div className="container pb-5">
      <div className="row justify-content-between mt-4">
        <div className="col-md-7 col-xl-6">
          <h3 className="mt-2 mb-4" data-test="personal-info-title">
            A few more details...
          </h3>
          <div className="col-md-12 mb-3">
            <ChangeBag
              bagName={formRef.current.bagName}
              label={formRef.current.blurb}
              onClick={() => {
                navman.reactivateSubscription(
                  new URLSearchParams(window.location.search)
                );
              }}
              btnStyle={fieldStyles}
            />
          </div>
          <div className="form-group">
            <label>Delivery Address</label>
            <AddressFinder
              style={fieldStyles}
              onAddressChange={async (addressState) => {
                setPafId(addressState.pafId);
                await handleAddressChange(
                  addressState.pafId,
                  addressState.fullAddress
                );
                formRef.current.isDeliveryAddressModified = true;
              }}
              fullAddress={formRef.current.fullAddress}
            />
            {formErrors.deliveryAddressError && (
              <span className="d-block mt-2 form-control-feedback text-danger">
                {formErrors.deliveryAddressError}
              </span>
            )}
          </div>
          <div className="row">
            <div className="col-md-11">
              <div className="form-group">
                <label>Delivery Instructions</label>
                <textarea
                  name="deliveryInstructions"
                  placeholder="e.g. Leave under carport"
                  className="form-control w-100 py-1 bg-transparent"
                  maxLength={100}
                  defaultValue={formRef.current.deliveryInstructions}
                  onChange={(e) =>
                    (formRef.current = {
                      ...formRef.current,
                      deliveryInstructions: e.target.value,
                      isDeliveryInstructionsModified: true,
                    })
                  }
                  spellCheck="false"
                  style={fieldStyles}
                />
                <small className="text-muted">
                  Instructions must be 100 characters or less.
                </small>
              </div>
            </div>
            <div className="col-md-1 overflow-hidden"></div>
          </div>
          <DeliverySlotSelect
            bagId={formRef.current.bagId}
            pafId={pafId}
            label={'Delivery Schedule Day and Time'}
            style={fieldStyles}
            errorMessage={formErrors.deliveryScheduleDayAndTimeError}
            onDefaultOption={(slot)=> defaultSlotLabel.current = slot.label}
            onChange={(
              deliveryId,
              weekStarting,
              deliveryDate,
              nextVirtualdeliveryId,
              label
            ) => {
              formRef.current = {
                ...formRef.current,
                deliveryDateId: nextVirtualdeliveryId,
                deliverySlotLabel: label
              };
              setErrors({ deliveryScheduleDayAndTimeError: undefined });
              validatePromo();
            }}
          />
        </div>
        <div className="col-md-4 mt-md-4">
          <PromoInputField
            label={'Promo code'}
            className="w-100 flex-fill"
            style={{
              ...fieldStyles,
              borderBottomRightRadius: 0,
              borderTopRightRadius: 0,
              backgroundImage: formRef.current.isValidPromo ? 'none' : '',
            }}
            defaultValue={formRef.current.promoCode}
            isLoading={isPromoLoading}
            errorMessage={formErrors.promoCodeError}
            onChange={(e) => {
              if (e.target.value.length == 0) {
                setErrors({ promoCodeError: undefined });
                if (formRef.current.isValidPromo == false) {
                  setPromoBanner(false);
                }
              }
              formRef.current = {
                ...formRef.current,
                promoCode: e.target.value,
              };
            }}
            onClick={async (value) => {
              setPromoLoading(true);
              formRef.current = {
                ...formRef.current,
                promoCode: value,
              };
              await validatePromo();
              setPromoLoading(false);
            }}
            successMessage={
              formRef.current.isValidPromo === true &&
              !formErrors.promoCodeError &&
              formRef.current.promoCode.length > 0
                ? 'Code successfully applied!'
                : ''
            }
          />
          <div>
            {deliveryDiscount.message && (
              <ThemeProvider
                theme={{
                  ...AccountTheme,
                  colors: {
                    ...AccountTheme.colors,
                    ...benefitDisplayTheme.colors,
                  },
                }}
              >
                <OrderSummary
                  promoOrReferral={deliveryDiscount}
                  showSummary={false}
                />
              </ThemeProvider>
            )}
          </div>
        </div>
        <div className="col-md-8 mt-4">
          {priceContext && <ProductPriceDisplay context={priceContext} />}
          <div className="row">
            <SubmitOrBack
              btnStyle={fieldStyles}
              submitLabel={'Start My Subscription'}
              backLabel={'Cancel'}
              containerClassName={'col-md-12 mb-2 flex-row-reverse'}
              classNameSpacer={'order-lg-2'}
              wrapperClassName={'d-flex justify-content-start flex-wrap'}
              backClassName={
                'btn btn-secondary text-nowrap col-lg-5 mt-3 order-lg-1 mb-lg-0 mb-4'
              }
              submitClassName={
                'btn btn-primary text-nowrap col-lg-5 mt-3 order-lg-3'
              }
              submitStage={formErrors.submitStage}
              onSubmit={handleSubmit}
              onBack={() => {
                navman.reactivateSubscription(
                  new URLSearchParams(window.location.search)
                );
              }}
            />
          </div>
          <TermsAndConditionsMessage />
        </div>
      </div>
      <MerchandisingSlots {...promoImageBanner} />
    </div>
  );
};

export const ReactivateSubscriptionPhase2 = connect(null, {
  setValidationMessages,
  setIsValidPromo,
})(ReactivateSubscriptionPhase2Unconnected);
