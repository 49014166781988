import routeHistory from './routerHistory';
import {AccountSettings, Week} from './app/shared/CoreClient';
import {isPlainObject} from 'lodash';
import {PYWStep} from '@mfb/cookbook';
import {
  rootPath,
  unauthorizedPath,
  errorPath,
  personalDetailsPath,
  personalDetailsEditPath,
  notFoundPath,
  deliveryNotFoundPath,
  deliveriesPath,
  manageAccountPath,
  subscriptionsAddPath,
  subscriptionsViewPath,
  campaignPath,
  subscriptionsRestartPath,
  subscriptionsCancelPath,
  subscriptionsPath,
  calendarPath,
  holidaysPath,
  subscriptionsCancelPathV1,
  subscriptionsViewPathLegacy,
} from './app/account-settings/components/Nav/paths';
import {ReactivateSubscriptionPhase2Props} from './app/shared/components/ReactivateSubscriptionPhase2';

export interface PlanYourWeekNavConfig {
  week?: string;
  step?: PYWStep;
  subNumber?: string;
  scrollTo?: string;
  skus?: Array<string>;
  promoCode?: string;
}

class Navman {
  public unauthorized(): void {
    routeHistory.history.push(unauthorizedPath);
  }

  public error(): void {
    routeHistory.history.push(errorPath);
  }

  public viewPersonalDetails = (): void => {
    routeHistory.history.push(personalDetailsPath);
  };

  public editPersonalDetails = (): void => {
    routeHistory.history.push(personalDetailsEditPath);
  };

  public reload(): void {
    window.location.href = `${rootPath}/`;
  }

  public notFound() {
    routeHistory.history.push(notFoundPath);
  }

  public deliveryNotFound() {
    routeHistory.history.push(deliveryNotFoundPath);
  }

  public openExternal(url: string){
    window.open(url)
  }

  public getCurrentPath() {
    return window.location.pathname.split('?')[0];
  }

  public addSubscription = (
    e?: React.MouseEvent<HTMLAnchorElement>,
    primaryBagId?: number
  ): void => {
    if (e) {
      e.preventDefault();
    }
    if (primaryBagId != null) {
      routeHistory.history.push(`${subscriptionsAddPath}/${primaryBagId}`);
    } else {
      routeHistory.history.push(subscriptionsAddPath);
    }
  };

  public restartEntireSubscription = (promoCode?: string): void => {
    routeHistory.history.push(subscriptionsAddPath, {
      isRestartFlow: true,
      promoCode,
    });
  };

  public addSubscriptionWithOptions = (
    options: Array<number>,
    campaignCode: string
  ): void => {
    routeHistory.history.push(
      `${subscriptionsAddPath}?campaignCode=${campaignCode}&options=${options.join(
        ','
      )}`
    );
  };

  public editSubscriptionWithOptions = (
    options: Array<number>,
    campaignCode: string,
    subscriptionNumber: string = null
  ): void => {
    routeHistory.history.push(
      `${subscriptionsViewPath}/${subscriptionNumber}?campaignCode=${campaignCode}&options=${options.join(
        ','
      )}`
    );
  };

  public editSubscription = (subscriptionId: number, subscriptionNumber: string = null): void => {
    const subscription = subscriptionNumber ?? subscriptionId;
    routeHistory.history.push(`${subscriptionsViewPath}/${subscription}`);
  };
  public restartSubscription = (primaryBagId: number): void => {
    routeHistory.history.push(`${subscriptionsRestartPath}/${primaryBagId}`);
  };

  public cancelSubscription = (subscriptionId: number): void => {
    routeHistory.history.push(`${subscriptionsCancelPath}/${subscriptionId}`);
  };

  public cancelSubscriptionV1 = (subscriptionId: number, subscriptionNumber: string = null): void => {
    routeHistory.history.push(`${subscriptionsCancelPathV1}/${subscriptionNumber}`);
  };

  public holidays = (): void => {
    routeHistory.history.push(holidaysPath);
  };

  public campaign = (campaignCode: string): void => {
    routeHistory.history.push(`${campaignPath}/${campaignCode}`);
  };

  public campaignModal = (campaignCode: string): void => {
    var queryParam = new URLSearchParams();
    queryParam.set('campaignCode', campaignCode);

    var path = `${deliveriesPath}?${queryParam.toString()}`;
    routeHistory.history.push(path);
  };

  public planYourWeek = (configuration?: PlanYourWeekNavConfig): void => {
    const params = new URLSearchParams(window.location.search);

    if (configuration) {
      for (const key in configuration) {
        params.set(key, encodeURIComponent(configuration[key]));
      }
    }

    routeHistory.history.push(
      `${deliveriesPath}/plan-your-week?${params.toString()}`
    );
  };

  public relativePath = (url: string): void => {
    routeHistory.history.push(rootPath + '/' + url);
  };

  public reactivateSubscription = (queryParams?): void => {
    const params = new URLSearchParams(window.location.search);

    routeHistory.history.push(
      `${deliveriesPath}/reactivate${
        queryParams
          ? '?' + queryParams.toString()
          : params
          ? '?' + params.toString()
          : ''
      }`
    );
  };

  public continueReactivation = (
    ReactivateSubscriptionPhase2Model: ReactivateSubscriptionPhase2Props
  ): void => {
    const params = new URLSearchParams(window.location.search);

    routeHistory.history.push(
      `${deliveriesPath}/continue-reactivate${
        params ? '?' + params.toString() : ''
      }`,
      {...ReactivateSubscriptionPhase2Model}
    );
  };

  public myOrders = (accountSettingsModel?: AccountSettings): void => {
    if (isPlainObject(accountSettingsModel)) {
      routeHistory.history.push(subscriptionsPath, {accountSettingsModel});
    } else {
      routeHistory.history.push(subscriptionsPath);
    }
  };

  public yourAccount = (): void => {
    routeHistory.history.push(deliveriesPath);
  };

  public accountSettings = (): void => {
    routeHistory.history.push(manageAccountPath);
  };

  public calendar = (): void => {
    routeHistory.history.push(calendarPath);
  };

  // n.b. on desktop manageAccountPath opens the same view as subscriptionsPath,
  // but on mobile these are two different views - it's more accurate to specify
  // subscriptionsPath if you want the user to see their active subscriptions as
  // this results in the "same" view regardless of viewport.
  public subscriptions = (): void => {
    routeHistory.history.push(subscriptionsPath);
  };

  public backToApp = (): void => {
    window.location.replace('myfoodbag://pleasetakemebacktotheapp');
  };

  /*
   * Used to resolve the origin, window.location.origin is not defined in IE<11
   */
  public resolveOrigin(): string {
    const loc = window.location;
    const port = window.location.port ? `:${loc.port}` : '';
    if (!loc.origin) {
      return `${loc.protocol}//${loc.hostname}${port}`;
    }

    return `${loc.origin}`;
  }
}

export const navman = new Navman();
