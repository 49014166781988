import FooterLegacy from 'app/shared/components/FooterLegacy';
import HeaderLegacy from 'app/shared/components/HeaderLegacy';
import { useAlertToastContext } from 'app/shared/hooks';
import { enGB } from 'date-fns/locale';
import { History } from 'history';
import React from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MapDispatchToProps, connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import styled from 'styled-components';
import { AlertToast } from '@mfb/lego';
import './App.css';
import AccountSettingsShell from './app/account-settings/AccountSettingsShell';
import './app/account-settings/components/Holidays/DatePicker/DatePickerOverrideStyles.css';
import {
  calendarPath,
  customerRedirectPath,
  deliveriesPath,
  errorPath,
  manageAccountPath,
  rateRecipesPath,
  rootPath,
  unauthorizedPath,
} from './app/account-settings/components/Nav/paths';
import YourAccountApp from './app/deliveries';
import { PlanYourWeekPageLoader } from './app/planYourWeek/PlanYourWeekPageLoader';
import RateRecipesShell from './app/rate-recipes/RateRecipesShell';
import { AccountFeatures, MessageVariant } from './app/shared/CoreClient';
import AlertMessage from './app/shared/components/AlertMessage';
import { Calendar } from './app/shared/components/Calendar';
import { CustomerRedirect } from './app/shared/components/CustomerRedirect';
import { DownloadiOsAppModal } from './app/shared/components/DownloadiOsAppModal';
import Footer from './app/shared/components/Footer';
import { Header } from './app/shared/components/Header';
import MobileStickyHeader from './app/shared/components/MobileStickyHeader/MobileStickyHeader';
import { ReactivateSubscription } from './app/shared/components/ReactivateSubscription';
import { ReactivateSubscriptionPhase2 } from './app/shared/components/ReactivateSubscriptionPhase2';
import PromoBanner from './app/shared/components/ReactivationPromoBanner';
import { PromoBannerProps } from './app/shared/components/ReactivationPromoBanner';
import ScrollToTop from './app/shared/components/ScrollToTop';
import { navman } from './navigator';
import {
  setIsValidPromo,
  setValidationMessages,
} from './redux/actions/sharedActions/bagStateAction';
import { AppState, BagState } from './redux/state';

const StyledMobilePaddingSpacer = styled.div`
  @media ${(p) => p.theme.layout.deviceMediaQueries.max.lg} {
    /** Move the content up from behind fixed Mobile Footer Nav  */
    padding-bottom: 60px;
  }
`;

const AlertToastContainer = styled.div`
  @media ${(p) => p.theme.layout.deviceMediaQueries.max.sm} {
    width: 100%;
    bottom: 60px; /* offset the nav bar */
    margin-bottom: 16px;
    z-index: 1060;
  }

  position: fixed;
  margin-top: 16px;
  max-width: 448px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1060;
`;

toast.configure();
registerLocale('enGB', enGB);

const notFound: React.FunctionComponent<any> = () => (
  <div className="container-fluid">
    <AlertMessage
      variant={MessageVariant.Danger}
      title="Sorry, we could not find that page"
      text="Click the button below to refresh the page or contact our support team for assistance."
      button={{ label: 'Refresh!', onClick: () => navman.reload() }}
    />
  </div>
);

const error: React.FunctionComponent<any> = () => (
  <div className="container-fluid">
    <AlertMessage
      variant={MessageVariant.Danger}
      title="Sorry, something went wrong"
      text="Click the button below to refresh the page or contact our support team for assistance."
      button={{ label: 'Refresh!', onClick: () => navman.reload() }}
    />
  </div>
);

const unauthorized: React.FunctionComponent<any> = () => (
  <div className="container-fluid">
    <AlertMessage
      variant={MessageVariant.Danger}
      title="Sorry you do not have the correct permissions to access this page"
    />
  </div>
);

const subdirectory: React.FunctionComponent<any> = (props) => (
  <StyledMobilePaddingSpacer>
    <Switch>
      <Route exact path={deliveriesPath} component={YourAccountApp} />
      <Route exact path={calendarPath} component={Calendar} />

      <Route exact path={rateRecipesPath} component={RateRecipesShell} />
      <Route path={customerRedirectPath} render={() => <CustomerRedirect />} />

      <Route
        exact
        path={`${deliveriesPath}/plan-your-week`}
        component={PlanYourWeekPageLoader}
      />
      <Route
        exact
        path={`${deliveriesPath}/reactivate`}
        component={ReactivateSubscription}
      />
      <Route
        exact
        path={`${deliveriesPath}/continue-reactivate`}
        component={ReactivateSubscriptionPhase2}
      />
      <Route path={manageAccountPath} component={AccountSettingsShell} />
      <Route exact path={unauthorizedPath} component={unauthorized} />
      <Route exact path={errorPath} component={error} />
      <Redirect exact from={rootPath} to={deliveriesPath} />
      <Route component={notFound} />
    </Switch>
  </StyledMobilePaddingSpacer>
);

interface Props {
  history?: History;
  validationErrors: string[];
  isValidPromo: boolean;
  features?: AccountFeatures;
}
type StoreProps = BagState & Props;
export interface DispatchProps {
  setValidationMessages;
}

const RenderHeader = (
  dashboardV2?: boolean,
  currentPath?: string,
  banner?: PromoBannerProps
) => {
  switch (dashboardV2) {
    case undefined:
      return <></>;
    case true:
      return (
        <Header currentPathName={currentPath} PromoBanner={banner}>
          <PromoBanner {...banner} />
        </Header>
      );
    case false:
      <PromoBanner {...banner} />;
      return <HeaderLegacy />;
  }
};

const RenderFooter = (dashboardV2?: boolean, currentPath?: string) => {
  switch (dashboardV2) {
    case undefined:
      return <></>;
    case true:
      return <Footer currentPathName={currentPath} />;
    case false:
      return <FooterLegacy currentPathName={currentPath} />;
  }
};

const App: React.FunctionComponent<any> = (props: Props) => {
  const { toast, closeToast } = useAlertToastContext();

  return (
    <Router history={props.history}>
      <ScrollToTop>
        <Switch>
          <Route
            path={`${deliveriesPath}/plan-your-week`}
            render={() => null}
          />
          <Route path={customerRedirectPath} render={() => null} />
          <Route /* This route is only to display the banner for the reactivation form and will effect deliveries path only*/
            path={deliveriesPath}
            render={() => {
              return (
                <>
                  {RenderHeader(
                    props.features.dashboardV2,
                    props.history.location.pathname,
                    {
                      isValidPromo: props.isValidPromo,
                      validationErrors: props.validationErrors,
                    }
                  )}
                  <MobileStickyHeader />
                  {RenderFooter(
                    props.features.dashboardV2,
                    props.history.location.pathname
                  )}

                  <DownloadiOsAppModal />
                </>
              );
            }}
          />
          <Route
            path="/*"
            render={() => {
              return (
                <>
                  {RenderHeader(
                    props.features.dashboardV2,
                    props.history.location.pathname,
                    {
                      isValidPromo: props.isValidPromo,
                      validationErrors: props.validationErrors,
                    }
                  )}
                  <MobileStickyHeader />
                  <AlertToastContainer>
                    {toast && toast?.isOpen && (
                      <AlertToast
                        isOpen={toast?.isOpen}
                        placement="top"
                        millisecondsToDismiss={5000}
                        status={toast?.status}
                        variant="default"
                        fullSize={true}
                        onClose={() => closeToast()}
                      >
                        {toast?.text}
                      </AlertToast>
                    )}
                  </AlertToastContainer>
                  {RenderFooter(
                    props.features.dashboardV2,
                    props.history.location.pathname
                  )}
                  <DownloadiOsAppModal />
                </>
              );
            }}
          />
        </Switch>

        <Switch>
          {/* React router switches are buggy with redirects + redux
            https://github.com/ReactTraining/react-router/issues/5632 */}
          <Route path={rootPath} component={subdirectory} />
          <Redirect
            exact
            from="/manage-account*"
            to={`${manageAccountPath}*`}
          />
          <Redirect exact from="/*" to={`${rootPath}/*`} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

const mapStateToProps = (state: AppState): StoreProps => {
  return {
    validationErrors: state.bagState && state.bagState.validationErrors,
    isValidPromo: state.bagState && state.bagState.isValidPromo,
    features: state.features,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, any> = {
  setValidationMessages,
  setIsValidPromo,
};
export default connect<BagState, DispatchProps, StoreProps>(
  mapStateToProps,
  mapDispatchToProps
)(App) as any;
