import * as React from 'react';
import createUserManager from '../UserManager';
import {
  calendarPath,
  deliveriesPath,
  rateRecipesPath,
  referAFriendPath,
  subscriptionsPath,
} from '../../account-settings/components/Nav/paths';
import { manageAccountPath } from '../../account-settings/components/Nav/paths';
import { getIsIOSWebview } from '../getIsIOSWebview';
import routeHistory from '../../../routerHistory';
import {
  NavigationHeader,
  NavigationHeaderContext,
  NavigationHeaderLinkProps,
  NavigationHeaderVariant,
  ProfileMenuProps,
  SvgRafGiftIcon,
} from '@mfb/lego';
import AccountSettingsIcon from './AccountSettingsIcon';
import RateRecipesIcon from './RateRecipesIcon';
import DeliveryIcon from './DeliveriesIcon';
import { openZendesk } from '../Zendesk';
import { connect } from 'react-redux';
import { mapDispatchToProps } from 'app/account-settings/containers/accountSettingsContainer';
import { AppState, CustomerProfileState } from 'redux/state';
import { navman } from 'navigator';
import { useTranslation } from 'react-i18next';

interface GTMEvent {
  category: string;
  label: string;
  action: string;
}

const trackingCategory = 'main-nav';
const trackingAction = 'click';

interface NavigationLink extends NavigationHeaderLinkProps {
  mobileIcon?: React.ReactNode;
  event?: GTMEvent;
}

const MobileIconSize = 13;
const DesktopIconSize = 20;

export const navigationLinks: Array<NavigationLink> = [
  {
    title: 'Deliveries',
    mobileTitle: 'Deliveries',
    href: deliveriesPath,
    onClick: (href) => routeHistory.history.push(href),
    mobileIcon: <DeliveryIcon width={MobileIconSize} height={MobileIconSize} />,
    showActive: true,
    showActiveOnRelativePaths: {
      enabled: true,
      excludedPaths: [],
    },
    event: {
      category: trackingCategory,
      action: trackingAction,
      label: 'deliveries-nav',
    },
  },
  {
    title: 'Account',
    mobileTitle: 'Account',
    href: manageAccountPath,
    onClick: (href) => routeHistory.history.push(href),
    mobileIcon: (
      <AccountSettingsIcon width={MobileIconSize} height={MobileIconSize} />
    ),
    showActive: true,
    showActiveOnRelativePaths: {
      enabled: true,
      excludedPaths: [referAFriendPath],
    },
    event: {
      category: trackingCategory,
      action: trackingAction,
      label: 'manage-account-nav',
    },
  },
  {
    title: 'global.navigation.rateRecipes',
    mobileTitle: 'global.navigation.rateRecipes',
    href: rateRecipesPath,
    onClick: (href) => routeHistory.history.push(href),
    mobileIcon: (
      <RateRecipesIcon width={MobileIconSize} height={MobileIconSize} />
    ),
    showActive: true,
    event: {
      category: trackingCategory,
      action: trackingAction,
      label: 'rate-recipes-nav',
    },
  },
  {
    title: 'My Deals',
    mobileTitle: 'Deals',
    href: referAFriendPath,
    showActive: true,
    mobileIcon: (
      <SvgRafGiftIcon width={MobileIconSize} height={MobileIconSize} />
    ),
    onClick: (href) => routeHistory.history.push(href),
    event: {
      category: trackingCategory,
      action: trackingAction,
      label: 'my-deals-nav',
    },
  },
];

interface HeaderProps {
  currentPathName?: string;
  customerProfileState: CustomerProfileState;
}

export const HeaderUnconnected = ({
  currentPathName,
  customerProfileState,
  ...props
}: React.PropsWithChildren<HeaderProps>) => {
  const { t } = useTranslation();
  const logout = async () => {
    const userMgr = await createUserManager();
    await userMgr.logout();
  };

  const navigateToSubscription = () => {
    navman.reactivateSubscription();
  };

  if (getIsIOSWebview()) {
    return null;
  }

  const navHeaderItems = navigationLinks.map((link) => ({
    ...link,
    title: t(link.title),
    mobileTitle: t(link.mobileTitle),
    isActive: false,
  })) as Array<NavigationHeaderLinkProps>;

  const profileMenuProps = {
    firstName: customerProfileState.firstName,
    lastName: customerProfileState.lastName,
    productName: customerProfileState.productName,
    brandCode: customerProfileState.code,
    brandIconUrl: customerProfileState.iconSvgUrl,
    deliveryDate: customerProfileState.deliveryDate,
    deliveryTime: customerProfileState.deliveryTime,
    totalCredits: customerProfileState.availableCredit,
    signOutCallback: logout,
    productClickCallback: () => routeHistory.history.push(subscriptionsPath),
    manageSubscriptionsCallback: navigateToSubscription,
  } as ProfileMenuProps;

  const handleBackNavigation = () => {
    if (currentPathName === calendarPath) {
      routeHistory.history.push(deliveriesPath);
      return;
    }
    routeHistory.history.push(manageAccountPath);
  };

  return (
    <NavigationHeaderContext.Provider
      value={{
        onHelp: openZendesk,
        onLogo: () => routeHistory.history.push(deliveriesPath),
        onGoBack: handleBackNavigation,
        activePathName: currentPathName,
        navItems: navHeaderItems,
        displayBackArrowButton: (href) =>
          (currentPathName.startsWith(href) && href !== currentPathName) ||
          href === referAFriendPath,
        profileProps: profileMenuProps,
        profilePanelWidth: 350,
        isFixed: currentPathName !== calendarPath,
        headerVariant: NavigationHeaderVariant.OlympicBlackDefault,
      }}
    >
      <NavigationHeader>{props.children}</NavigationHeader>
    </NavigationHeaderContext.Provider>
  );
};

const mapStateToProps = (appState: AppState, props): HeaderProps => {
  const { customerProfileState } = appState;
  return {
    ...props,
    customerProfileState,
  };
};

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderUnconnected);
